<template>
    <div class="warper-list">

        <van-radio-group v-model="radioData"  >
            <div class="list" v-for="item in listData" :key="item.id">
                <van-radio :name="item">
                    <div class="box-right">
                        <van-field  label="车主："  v-model="item.type_value" />
                        <van-field  label="车牌号码："  v-model="item.carNumberPlate" />
                        <van-field  label="车架号：" v-model="item.vin"  />
                    </div>
                </van-radio>
            </div>
        </van-radio-group>


        <!-- <div class="list" v-for="item in listData" :key="item.id">
            <van-checkbox class="radio" v-model="item.checkbox" :name="item.id" icon-size="24px"></van-checkbox>
            <van-cell-group class="info" >
                <van-field  label="车主："  v-model="item.type_value" />
                <van-field  label="车牌号码："  v-model="item.carNumberPlate" />
                <van-field  label="车架号：" v-model="item.vin"  />
            </van-cell-group>
            <hr>
        </div> -->
        <van-button class="content-btn" type="info" size="large" @click="submit">确定</van-button>
    </div>
</template>

<script>
import { is_post } from "../../../Http/api.js";
import { encipherMent, encryptDecode } from '@/utils/encrypt'
import bus from '@/utils/bus'
    export default {
        data() {
            return {
                listData:[],
                result:[],
                IDtype_id_value:"",
                path:"",
                typeId:"",
                identification_back:"",radioData:"",
                bank_card_number:'',
                car_bank_front:'',
                car_bank_back:'',
            }
        },
        mounted () {
          
        },
        methods: {
            async submit() {
               try{
                //    let arr=[]
                //     this.listData.forEach(i => {
                //         let obj = {}
                //         if(i.checkbox){
                //             obj = {
                //                 id:i.id,
                //                 name:i.type_value,
                //                 type:this.typeId,
                //                 number:this.IDtype_id_value,
                //                 path:this.path,
                //                 identification_back:this.identification_back
                //             }
                //             arr.push(obj)
                //         }
                //     });
                    let obj = {
                        id:this.radioData.id,
                        name:this.radioData.type_value,
                        type:this.typeId,
                        number:this.IDtype_id_value,
                        path:this.path,
                        identification_back:this.identification_back,
                        bank_card_number:this.bank_card_number,
                        car_bank_front:this.car_bank_front,
                        car_bank_back:this.car_bank_back,
                    }
                    console.log(obj)
                    let aa = encipherMent( JSON.stringify({data:[obj]}))
                    let param = new URLSearchParams();
                    param.append("value",aa);
                    const data = await is_post('index.php/vehicles_upload/saveOwner',param).then(res=>res)
                    this.$toast.success(data.msg)
                    this.$router.push('/Recognition')
                }
                catch (err){
                    console.log(err)
                }
                
                
            }
        },
    }
</script>

<style lang="scss" scoped>
.warper-list{
    padding: 20px;
    .list{
 
        margin-top: 10px;
        border-bottom: 2px solid #eee;
    }
    .info{
        width: 90%;
    }
  
}
</style>