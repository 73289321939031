<template>
    <div>
        <div class="header">
             <span @click="gouot"></span>
             <span>车主信息</span>
             <span @click="islogout">退出</span>
        </div>
        <div class="music-t">
            <music />
        </div>
         <div class="content">
            <div @click="showPicker = true" :style="{color:'red',fontWeight:'bolder'}"> 
                <span>车主证件类型：</span>
                <span :style="{marginLeft:'10px'}">{{columns[(isData.type?(isData.type-1):ownerType)]}}</span>
            </div>
            <div class="content-up">
                <p v-if="(isData.type?isData.type:(ownerType+1)) === 1">身份证正面：</p>
                <p v-if="(isData.type?isData.type:(ownerType+1)) === 2">营业执照图片：</p>
                <div v-if="!isWechat">
                    <van-uploader v-model="fileList1" :after-read="uploadershang(1)" :before-delete="deleteimg(1)" preview-size="100%" :max-count="1">
                        <img style="width:100%" src="../../assets/zhanwu.jpg" alt="">
                    </van-uploader>
                </div>
                <div v-else>
                     <img v-if="!imageUrl" style="width:100%" src="../../assets/zhanwu.jpg" alt="" @click="WeChooseImage(1,'carInfo')">
                    <div v-else style="width:100%">
                        <van-image class="vanImage" style="width:100%"  :src="imageUrl" @click="bigImg(imageUrl)" >
                            <van-icon class="iconX" name="close" @click.stop="delImgHan('carInfo')" />
                        </van-image>
                    </div>
                </div>
            </div>
         
            <div class="content-up" v-if="(isData.type?isData.type:(ownerType+1)) === 1">
                <p>身份证反面：</p>
                <div v-if="!isWechat">
                    <van-uploader v-model="fileList2" :after-read="uploadershang(2)" :before-delete="deleteimg(2)" preview-size="100%" :max-count="1">
                        <img style="width:100%" src="../../assets/zhanwu.jpg" alt="">
                    </van-uploader>
                </div>
                <div v-else>
                     <img v-if="!imageUrlF" style="width:100%" src="../../assets/zhanwu.jpg" alt="" @click="WeChooseImage(1,'Fan')">
                    <div v-else style="width:100%">
                        <van-image class="vanImage" style="width:100%"  :src="imageUrlF" @click="bigImg(imageUrlF)" >
                            <van-icon class="iconX" name="close" @click.stop="delImgHan('Fan')" />
                        </van-image>
                    </div>
                </div>
            </div>
        </div>
        <div class="info" v-if="(isData.type?isData.type:(ownerType+1)) === 1">
            <div :style="{textAlign:'center',fontWeight:'bolder',marginBottom:'10px'}">车主身份证信息</div>
             <van-cell-group>
                <van-field label="姓名：" v-model="isData.name" placeholder="请输入身份证姓名"/>
                <van-field label="身份证号：" v-model="isData.idcard" placeholder="请输入身份证号码"/>
               
            </van-cell-group>
        </div>
        <div class="info" v-if="(isData.type?isData.type:(ownerType+1)) === 2">
            <div :style="{textAlign:'center',fontWeight:'bolder',marginBottom:'10px'}">车主营业执照信息</div>
             <van-cell-group >
                <van-field label="企业名称：" v-model="isData.enterprise" placeholder="请输入企业名称"/>
                <van-field label="信用代码：" v-model="isData.credit_code" placeholder="请输入社会信用代码"/>
            </van-cell-group>
        </div>
        <div class="card">
            <div style="color:blue;text-align: center;" @click="iscard=!iscard">添加银行卡信息</div>
            <div class="cardCon" v-if="iscard">
                <BankCardInfo ref="CardInfoRef" @bankZImg="bankZImg" @bankFImg="bankFImg" />
            </div>
        </div>
       <van-button class="content-btn" type="info" size="large" @click="submit">完成</van-button>
           <van-popup
                v-model="show"
                closeable
                :close-on-click-overlay="false"
                position="bottom"
                :style="{
                    height: '100%',
                
                }">
                <infoList ref="ListInfo" />
              
            </van-popup>
           <van-popup
                v-model="IsShow"
                closeable
                round
                :close-on-click-overlay="false"
                class="isPopup"
               >
                <p>未能匹配相关信息，请检查车主姓名是否正确</p>
            </van-popup>
            <van-popup  :closeable="true"  v-model="voiceShow" class="popup" :close-on-click-overlay="false">
              <p style="text-align: center; ">是否语音播报</p>
              <hr>
              
              <div class="btn-ok">
                  <van-button @click="voiceShow= false">取消</van-button>
                  <van-button type="info" @click="yuying">确定</van-button>
              </div>
        </van-popup>
        <audio :src="voice" id="audio"  ref="au"></audio>
        <van-popup v-model="showPicker" position="bottom">
        <van-picker
            show-toolbar
            :visible-item-count="4"
            :columns="columns"
            @cancel="showPicker = false"
            @confirm="onConfirm"
        />
        </van-popup>
    </div>
</template>

<script>
import { Toast,Dialog } from 'vant';
import { is_post } from "../../Http/api.js";
import infoList from './components/infoList';
import BankCardInfo from './components/BankCardInfo';
import wx from 'weixin-js-sdk'
import { deleteCookie } from '@/utils/env'
import music from '../../components/music.vue'

import { encipherMent, encryptDecode } from '@/utils/encrypt'
    export default {
        components: {
            infoList,music,BankCardInfo
        },
        data() {
            return {
                fileList1: [],
                fileList2: [],
                isData:{
                    type:1,
                },
                show:false,
                IsShow:false,
                voiceShow:false,
                iscard:false,
                imageUrl:'',
                imageUrlF:'',
                voice:'',
                wximagelocalids:[],//判断上传张数
                toast:null,//转圈圈
                ownerType:0,
                ownerTypeText:"请选择车主证件类型",
                showPicker: false,
                columns: ['身份证', '营业执照'],
                bankZ:"",
                bankF:"",
            }
        },
        mounted () {
            if(this.isWechat){
                this.wcConfig();
            }
        },
        watch: {
            wximagelocalids:{
                handler(newValue, oldValue){
                     if(newValue.length > 0 ){
                    this.toast = Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: "上传中..."
                    });
                    this.wxUpload(newValue[0])
                }else{
                    this.toast.clear();
                }
                },
                deep: true,
            },
        },
        methods: {
            bankZImg(e){
                this.bankZ = e
            },  
            bankFImg(e){
               
                this.bankF = e
            },  
            onConfirm(picker, value, index) {
                this.ownerType = value;
                this.isData.type=value+1
                console.log(this.isData.type)
                this.showPicker = false;
            },
            // autoPlay('music');
             isPlay(n){
                var toast = Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中..."
                });
                
                let text = ''
                if(n.type===1){
                    text = `姓名：${n.name},身份证号码：${n.idcard}`
                }else if(n.type===2){
                    text = `企业名称：${n.enterprise},社会信用代码：${n.credit_code}`
                }
                this.$http.post('index.php/wechat/makeYuYin',{text}).then(res=>{
                    
                    this.voice = res.data.data
                    toast.clear()//清除加载效果
                    var u = navigator.userAgent;
                    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                    if(isiOS){
                        this.voiceShow = true
                    }else{
                        this.$nextTick(()=>{
                            this.$refs.au.play()
                        })
                    }
                })
            },
            yuying(){
                this.$refs.au.play()
                 this.voiceShow = false
            },
             async submit(){
               
                try{
                    if(!this.isData.type){
                        this.isData.type=this.ownerType+1
                    }
                    let name = this.isData.type === 1 ?this.isData.name:this.isData.enterprise
                     let data = encipherMent( JSON.stringify({name}))
                    let param = new URLSearchParams();
                    param.append("value",data);
                    const res = await is_post('index.php/vehicles_upload/selOwner',param).then(res=>res)
                    console.log(res)
                    if(res.code === 0 ){
                        if(res.data.length === 0){
                            this.IsShow = true
                        }else{
                            this.show = true
                            this.$nextTick(()=>{
                                this.$refs.ListInfo.listData = res.data
                                this.$refs.ListInfo.path = this.imageUrl
                                this.$refs.ListInfo.identification_back =this.isData.type == 1 ? this.imageUrlF : undefined
                                this.$refs.ListInfo.typeId = this.isData.type
                                this.$refs.ListInfo.IDtype_id_value = this.isData.type === 1 ?this.isData.idcard:this.isData.credit_code
                                this.$refs.ListInfo.bank_card_number =this.$refs.CardInfoRef.isData.bank_card_number
                                this.$refs.ListInfo.car_bank_front = this.isWechat?this.$refs.CardInfoRef.bankZImg: this.bankZ 
                                this.$refs.ListInfo.car_bank_back = this.isWechat?this.$refs.CardInfoRef.bankFImg: this.bankF 
                            })
                        }
                    }
                }
                catch (err){
                  
                    console.log(err)
                }
                   
            },
            gouot() {
                this.$router.push('/Recognition')
            },
                //删除
            deleteimg(i){
                return ((file)=>{

                    if(i==1){
                        this.isData = {}
                    }else{
                        this.imageUrlF = ''
                    }
                    return true
                })
            },
            //上传照片
           uploadershang(num){
                return ((file)=>{
                    file.status = "uploading";
                    file.message = "上传中...";
                    let params = new FormData(); //创建form对象
                    params.append("file", file.file);
                    this.$http.post("/index.php/index/upload/index", params)
                    .then((res) => {
                    if (res.data.code == 0) {
                        if(num == 1){
                            let url = 'index.php/vehicles_upload/idcardBusinessLicense'
                            this.imageUrl = res.data.data
                            this.recognition(url,{path:res.data.data})
                            // this.islogout()
                        }else{
                            this.imageUrlF = res.data.data
                        }
                       
                        file.status = "done";
                        
                    } else if(res.data.code == -1){
                        deleteCookie('token')
                        this.$toast.fail(res.data.msg)
                        this.$router.push('/')
                    }else{
                        file.status = "failed";
                        file.message = "上传失败";
                        this.imageUrl=''
                        this.fileList1=[]//行驶证正面照片
                        this.fileList2=[]//行驶证正面照片
                    }
                    }).catch(()=>{
                        this.imageUrl = ''
                        this.fileList1=[],//行驶证正面照片
                        this.fileList2=[],//行驶证正面照片
                        file.status = "failed";
                        file.message = "上传失败";
                        this.$toast.fail('网络开小差了，请稍后重试！')
                    })
                })
                
           },

        },
    }
</script>

<style lang="scss" scoped>
.header {
    position: fixed;
    top: 0;
    z-index: 9;
    width: calc(100% - 40px);
    padding: 0 20px;
    background-color: cadetblue;
    height: 1rem;
    line-height: 1rem;
    color: white;
    font-size: 0.4rem;
    display: flex;
    justify-content: space-between;
    span {
        display: inline-block;
    }
    span:nth-child(1) {
        width: 30px;
        height: 30px;
        background-image: url(../../assets/u39.png);
        background-size: 100%;
        margin-top: 8px;
    }
}
.content{
    padding: 10px;
    padding-top: 50px;
    margin-top: 20px;
    text-align: center;
    .content-up{
        margin-top: 20px;
        padding: 10px;
      
    }
}
.info{
    margin:50px 0 ;
}
.isPopup{
    padding: 20px 15px;
    text-indent: 1em;
}
  .popup{
    width: 80%;
    padding: 20px;
    .btn-ok{
        width: 100%;
        display: flex;
        .van-button{
            flex: 1;
        }
    }
}
 .vanImage{
    position: relative;
    margin-right: 10px;
    margin-top: 10px;
        .iconX{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 25px;
        background: #fff;
        color: #000;
    }
}
.card{
    
    margin-bottom: 20px;
    .cardCon{
        margin-top: 10px;
    }
}
</style>
