<template>
    <div>
        <div class="content">
            <div class="content-up">
               
                <p>银行卡正面：</p>
                <div v-if="!isWechat">
                    <van-uploader v-model="fileList1" :after-read="uploadershang('bankZ')" :before-delete="deleteimg('bankZ')" preview-size="100%" :max-count="1">
                         <img  style="width:100%" src="../../../assets/zhanwu.jpg" alt="" >
                    </van-uploader>
                </div>
                <!-- v-else -->
                <div v-else >
                    <img v-if="!bankZImg" style="width:100%" src="../../../assets/zhanwu.jpg" alt="" @click="WeChooseImage(1,'bankZ')">
            
                    <div v-else style="width:100%">
                        <van-image class="vanImage" style="width:100%"  :src="bankZImg" @click="bigImg(bankZImg)" >
                            <van-icon class="iconX" name="close" @click.stop="delImgHan('bankZ')" />
                        </van-image>
                    </div>
                </div>
               
                
            </div>

            <div class="content-up">
               
                <p>银行卡反面：</p>
                <div v-if="!isWechat">
                    <van-uploader v-model="fileList2" :after-read="uploadershang('bankF')" :before-delete="deleteimg('bankF')" preview-size="100%" :max-count="1">
                        <img style="width:100%" src="../../../assets/zhanwu.jpg" alt="">
                    </van-uploader>
                </div>
                <div v-else>
                    <img v-if="!bankFImg" style="width:100%" src="../../../assets/zhanwu.jpg" alt="" @click="WeChooseImage(1,'bankF')">
                    <div v-else style="width:100%">
                        <van-image class="vanImage" style="width:100%"  :src="bankFImg" @click="bigImg(bankFImg)" >
                            <van-icon class="iconX" name="close" @click.stop="delImgHan('bankF')" />
                        </van-image>
                    </div>
                </div>
                  
            </div>
            <van-cell-group>
                <van-field placeholder="请填写银行卡号" label="银行卡号：" v-model="isData.bank_card_number" />
            </van-cell-group>
        </div>
    </div>
</template>

<script>
import bus from '@/utils/bus'
import { Toast } from 'vant'
    export default {
        data() {
            return {
                fileList1:[],
                fileList2:[],
                bankZImg:"",//正面
                bankFImg:"",//反面
                wximagelocalids:[],//判断上传张数
                toast:null,//转圈圈
                isData:{bank_card_number:''},
                isshibie:''
            }
        },
        watch: {
            wximagelocalids:{
                handler(newValue, oldValue){
                    
                     if(newValue.length > 0 ){
                    this.toast = Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: "上传中..."
                    });
                    
                    this.wxUpload(newValue[0])
                }else{
                    this.toast.clear();
                }
                },
                deep: true,
            }
        },
        mounted () {
            if(this.isWechat){
                this.wcConfig();
            }
        },
        methods: {
            deleteimg(e){
                return file=>{
                    if(e=='bankZ'){
                        this.fileList1 = []
                        this.bankZImg = ''
                        if(this.isshibie == 1){
                            
                            this.isData.bank_card_number = ''
                        }

                    }else if(e=='bankF'){
                        this.bankFImg = ''
                        this.fileList2 = []
                        if(this.isshibie == 2){

                            this.isData.bank_card_number = ''
                        }
                    }
                    
                }
            },
            uploadershang(e){
                return file=>{

                    file.status = "uploading";
                    file.message = "上传中...";
                    let params = new FormData(); //创建form对象
                    params.append("file", file.file);
                    this.$http.post("/index.php/index/upload/index", params)
                    .then((res) => {
                    if (res.data.code == 0) {
                        let url = '/index.php/index/Image_Recognition/bankcard'
                        if(e=='bankZ'){
                            this.bankZImg = res.data.data
                            this.$emit('bankZImg', res.data.data);
                            if(!this.isData.bank_card_number){
                                this.isshibie = 1
                                this.recognition(url,{path:res.data.data},'bankZ')
                            }
                        }else if(e=='bankF'){
                            this.$emit('bankFImg', res.data.data);
                            this.bankFImg = res.data.data
                            if(!this.isData.bank_card_number){
                                
                                this.isshibie = 2
                                this.recognition(url,{path:res.data.data},'bankF')
                        }
                        }
                       
                        file.status = "done";
                    } else if(res.data.code == -1){
                       this.islogout()
                    }else{
                        if(e=='bankZ'){
                            this.fileList1 = []
                        }else if(e=='bankF'){
                            this.fileList2 = []
                        }
                        file.status = "failed";
                        file.message = "上传失败";
                        this.proveUrl=''
                        this.fileList1=[]//行驶证正面照片
                    }
                    }).catch(()=>{
                        if(e=='bankZ'){
                            this.fileList1 = []
                        }else if(e=='bankF'){
                            this.fileList2 = []
                            
                        }
                        file.status = "failed";
                        file.message = "上传失败";
                        this.$toast.fail('网络开小差了，请稍后重试！')
                    })
                }
           },
        },
    }
</script>

<style lang="scss" scoped>
.content{
    padding: 10px;
    text-align: center;
    .content-up{
        margin-top: 20px;
        padding: 10px;
      
    }
}
.vanImage{
    position: relative;
    margin-right: 10px;
    margin-top: 10px;
        .iconX{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 25px;
        background: #fff;
        color: #000;
    }
}
</style>